import { navigate } from '@reach/router'

import React, { FunctionComponent, useContext, useState } from 'react'
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap'

import { Api } from '../api'
import { JMM } from '../jmm_schema'

import '../assets/css/navbar.css'

// i18n
import { Context, translate } from './language_provider'

interface NavbarProps {
  user?: (JMM.StoreUser & JMM.idd) | undefined
}

export const NavBar: FunctionComponent<NavbarProps> = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const context = useContext(Context)

  return (
    <Navbar color='default' dark expand='md'>
      <NavbarBrand href='/'>JMM</NavbarBrand>
      <NavbarToggler onClick={toggle} />

      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className='expand'>
          <NavItem>
            <NavLink href={`/users`}> {translate('Users')} </NavLink>
          </NavItem>

          <NavItem>
            <NavLink href={`/products`}> {translate('Products')} </NavLink>
          </NavItem>

          <NavItem>
            <NavLink href={`/companies`}> {translate('Companies')} </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href='https://jmm.com.mx/'> jmm.com.mx </NavLink>
          </NavItem>
          {user && (
            <NavItem className='justify-self-end'>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {user?.name}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => navigate(`/users/${user.uid}`)}>
                    {translate('Profile')}
                  </DropdownItem>

                  <DropdownItem divider />

                  <DropdownItem
                    onClick={() => context.selectLang('en-US')}
                    active={context.locale.substring(0, 2) === 'en'}
                  >
                    {translate('English')}
                  </DropdownItem>

                  <DropdownItem
                    onClick={() => context.selectLang('es-MX')}
                    active={context.locale.substring(0, 2) === 'es'}
                  >
                    {translate('Spanish')}
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem onClick={() => Api.Auth.signOut()}>
                    {translate('Log out')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}
