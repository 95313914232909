import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import English from '../translations/en.json'
import Spanish from '../translations/es.json'

export const translate = (id: string, defaultMessage?: string, description?: string | object | undefined, values?: object | undefined) => <FormattedMessage id={id} defaultMessage={defaultMessage} description={description} values={values} />;

export const Context = React.createContext<{ locale: string, selectLang: ((lang: string) => void) }>({ locale: '', selectLang: () => { } });

export type Lang = (lang: string) => void;

export const IntlContextProvider: FunctionComponent = props => {
  const [locale, setLocale] = useState('');
  const [messages, setMessages] = useState({});

  const getMessages = (lang: string) => {
    const trimmedLang = lang.substring(0, 2);
    switch (trimmedLang) {
      case 'es':
        return Spanish
      case 'en':
        return English
      default: // fallback
        return English
    }
  }

  useEffect(() => {
    const preLocale = navigator.language;
    const storedLocale = localStorage.getItem('locale');
    if (!storedLocale) {
      setLocale(preLocale);
      setMessages(getMessages(preLocale))
      localStorage.setItem('locale', preLocale);
    } else {
      setLocale(storedLocale);
      setMessages(getMessages(storedLocale));
    }
  }, [locale, messages]);

  const selectLang: Lang = (lang: string) => {
    const newLocale = lang;
    setLocale(newLocale);
    setMessages(getMessages(newLocale));
    localStorage.setItem('locale', newLocale);
  }

  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
}


