import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import { Badge, Col, Container, Label, Row, Table } from 'reactstrap'

import { LoadingScreen } from '../components/loading'

import { Api } from '../api'
import { JMM } from '../jmm_schema'
import { JMMImpl } from '../utils/jmm_impl'

import { translate } from '../components/language_provider'

const compareRecords = (
  a: { timeSpan: JMM.TimeSpan },
  b: { timeSpan: JMM.TimeSpan },
  key: keyof JMM.TimeSpan = 'to'
) => a.timeSpan[key].seconds - b.timeSpan[key].seconds

interface ProductViewProps {
  uid: string
}
interface ProductViewState {
  product: JMM.Product

  modal: boolean
  attachmentUrl: string
  ready: boolean
}
export class ProductView extends Component<
  RouteComponentProps<ProductViewProps>,
  ProductViewState
  > {
  state = {
    product: new JMMImpl.ProductIMPL(),
    ready: false,
    modal: false,
    attachmentUrl: '',
  }

  async componentDidMount() {
    const { uid, navigate } = this.props
    try {
      const storeProduct = await Api.Product.read(uid!)
      this.setState({ product: storeProduct, ready: true })
    } catch (e) {
      console.error(e)
      navigate!('/')
    }
  }

  toggleModal = () => this.setState(({ modal }) => ({ modal: !modal }))

  render() {
    if (!this.state.ready) return <LoadingScreen />
    const {
      product: { serial, name, description, company, area, log },
    } = this.state
    return (
      <Container fluid>
        <Row>
          <h1>{`#${serial}`}</h1>
        </Row>
        <Row>
          <Col>
            <Label>{translate('Name')}:</Label>
            <p>{name}</p>
          </Col>
          <Col>
            <Label>{translate('Description')}:</Label>
            <p>{description}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{translate('Company')}:</Label>
            <p>{company}</p>
          </Col>
          <Col>
            <Label>{translate('Area')}:</Label>
            <p>{area}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{translate('Product log')}:</Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{translate('Next expiring')}:</Label>
          </Col>
          <Col>
            <p>
              {(() => {
                const {
                  name,
                  timeSpan: { to },
                } = log.sort(compareRecords)[0]
                return `${name} ${translate('ending in')} ${to.toDate().toLocaleDateString()}`
              })()}
            </p>
          </Col>
        </Row>
        <Row className='flex-grow-1 overflow-scroll'>
          <Table className='align-items-center' responsive>
            <thead className='thead-light'>
              <tr>
                <th>{translate('Name')}</th>
                <th>{translate('From')}</th>
                <th>{translate('To')}</th>
                <th>{translate('Attatchments')}</th>
              </tr>
            </thead>
            <tbody>
              {log
                .sort((a, b) => compareRecords(a, b, 'from'))
                .reverse()
                .map(({ name, timeSpan: { to, from }, attachments }, idx) => (
                  <tr key={`${name}|${to}|${from}|${attachments?.length || 0}`}>
                    <td>{name}</td>
                    <td>{`${to.toDate().toLocaleDateString()}`}</td>
                    <td>{`${from.toDate().toLocaleDateString()}`}</td>
                    {attachments && (
                      <td className='d-flex flex-wrap'>
                        {attachments.map(att => (
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={att.url}
                            key={att.url}
                          >
                            <Badge color='primary'>{att.name}</Badge>
                          </a>
                        ))}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      </Container>
    )
  }
}
