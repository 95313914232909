import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'

import { JMM } from '../jmm_schema'

import { Button, Col, Container, Row } from 'reactstrap'
import { NavBar } from '../components/navbar'

import { LoadingScreen } from '../components/loading'

import { EntityApi } from '../api'

import { translate } from '../components/language_provider'

import {
  InfiniteLoader,
  IndexRange,
  AutoSizer,
  Table,
  Column,
} from 'react-virtualized'

import '../assets/css/collection.css'
import { firestore } from 'firebase'

interface CollectionFormProps<T> {
  api: EntityApi<T>
  entityClass: any
}

interface CollectionState<T> {
  loading: boolean
  fetching: boolean
  exhausted: boolean
}

const upperCaseFirst = (str: string) => str[0].toUpperCase() + str.slice(1)

export class CollectionForm<T> extends Component<
  RouteComponentProps<CollectionFormProps<T>>,
  CollectionState<T>
> {
  state = { loading: true, fetching: false, exhausted: false }

  lastSnapshot: firestore.QueryDocumentSnapshot<T> | null = null

  entities: (T & JMM.idd)[] = []

  entityPoperties = Object.getOwnPropertyNames(
    new this.props.entityClass()
  ).slice(
    0,
    Math.min(3, Object.getOwnPropertyNames(new this.props.entityClass()).length)
  )

  loadingEntity = (this.entityPoperties as (keyof T)[]).reduce<Partial<T>>(
    (acc, cur) => {
      acc[cur] = 'loading' as any
      return acc
    },
    {}
  )

  async componentDidMount() {
    await this.fetch()
    this.setState({ loading: false })
  }

  render() {
    const { exhausted, loading, fetching } = this.state
    const { api, navigate } = this.props
    if (loading) return <LoadingScreen />
    return (
      <>
        <NavBar />
        <Container className='d-flex flex-column max-height-viewport ' fluid>
          <Row className='collection-controls'>
            <Col md={10}>
              <h1>{translate(`${upperCaseFirst(api!._collectionPath)}`)}</h1>
            </Col>
            <Col md={2} className=''>
              <Button onClick={() => navigate!('new')} type='button'>
                {translate('Add')}
              </Button>
            </Col>
          </Row>

          <Row className='flex-grow-1 overflow-scroll'>
            {this.InfiniteList({
              hasNextPage: !exhausted,
              isNextPageLoading: fetching,
              list: this.entities,
              loadNextPage: () => this.fetch(),
            })}
          </Row>
        </Container>
      </>
    )
  }

  async fetch() {
    this.setState({ fetching: true })

    let newDocsSnapshots
    if (this.lastSnapshot) {
      newDocsSnapshots = await this.props.api!.readAfter(this.lastSnapshot, 10)
    } else {
      newDocsSnapshots = await this.props.api!.readLimit(10)
    }

    let exhausted = false
    if (newDocsSnapshots.length) {
      this.lastSnapshot = newDocsSnapshots[newDocsSnapshots.length - 1]
      this.entities.push(
        ...newDocsSnapshots.map(snapshot => ({
          uid: snapshot.id,
          ...snapshot.data(),
        }))
      )
    } else {
      exhausted = true
    }

    this.setState({ fetching: false, exhausted })
  }

  InfiniteList({
    hasNextPage,
    isNextPageLoading,
    list,
    loadNextPage,
  }: {
    hasNextPage: boolean
    isNextPageLoading: boolean
    list: (T & JMM.idd)[]
    loadNextPage: (params: IndexRange) => Promise<any>
  }) {
    const rowCount = hasNextPage ? list.length + 1 : list.length
    const loadMoreRows = isNextPageLoading ? async () => {} : loadNextPage
    const isRowLoaded = ({ index }: { index: number }) =>
      !hasNextPage || index < list.length

    return (
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <Table
                className={'Table'}
                ref={registerChild}
                headerClassName={'headerColumn'}
                headerHeight={50}
                rowClassName={'evenRow'}
                rowHeight={50}
                rowGetter={({ index }) => {
                  if (index >= this.entities.length) return this.loadingEntity
                  return this.entities[index]
                }}
                rowCount={rowCount}
                width={width - 10}
                height={window.innerHeight - 180}
                onRowsRendered={onRowsRendered}
                onRowClick={({ index }) =>
                  this.props.navigate!(
                    `/${this.props.api!._collectionPath}/${list[index].uid}`
                  )
                }
              >
                {this.entityPoperties.map(prop => (
                  <Column
                    key={prop}
                    className={'exampleColumn'}
                    label={upperCaseFirst(prop)}
                    dataKey={prop}
                    width={200}
                  />
                ))}
              </Table>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
}
