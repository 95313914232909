import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent, useState } from 'react'
import QrReader from 'react-qr-reader'

import Lottie from 'react-lottie'
import * as animationData from '../assets/animations/1252-loading.json'

import { Button, Col, Container, Row } from 'reactstrap'

import { cloneDeep } from 'lodash'
import { Api } from '../api'
import { JMM } from '../jmm_schema'
import { Validation } from '../utils/validation'

import { translate } from '../components/language_provider'

interface HomeProps {
  user: JMM.StoreUser
}
interface HomeState {
  scanResult: string
  error?: string
}
export const Home: FunctionComponent<RouteComponentProps<HomeProps>> = ({
  navigate,
  user,
}) => {
  const [scanResult, setScanResult] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [legacyNeeded, setLegacyNeeded] = useState(false)
  const [storeProduct, setStoreProduct] = useState<
    (JMM.Product & JMM.idd) | null
  >(null)
  let reader: QrReader | null = null

  const handleScan = async (scannedValue: string | null) => {
    const credentials = user?.credentials || {}
    if (!scannedValue) return
    if (!Validation.serialPattern.test(scannedValue))
      return setError('Scanned data is not a valid product serial number.')

    const queriedProduct = await Api.Product.readWithSerial(scannedValue)
    if (
      !queriedProduct ||
      !(queriedProduct.company in credentials) ||
      !(queriedProduct.area in credentials[queriedProduct.company])
    )
      return setError(
        'Scanned serial number is not in database available to user.'
      )

    setStoreProduct(cloneDeep(queriedProduct))
    setError(undefined)
    setScanResult(scannedValue)
  }

  const handleError = ({
    name: errorName,
    message: errorMessage,
  }: {
    name: string
    message: string
  }) =>
    setError(
      (
        ({
          NoVideoInputDevicesError: () => {
            setLegacyNeeded(true)
            return 'Could not get camera stream directly. Tap here to use a picture instead.'
          },
          NotAllowedError: () => {
            setLegacyNeeded(true)
            return 'Camera permission denied. Tap here to use a picture instead.'
          },
        } as { [k: string]: () => string })[errorName] ||
        (() => {
          return errorMessage
        })
      )()
    )

  const flexCenter =
    'd-flex flex-fill align-items-center justify-content-center'
  return (
    <Container fluid style={{ touchAction: 'none' }}>
      <Row className='d-flex justify-content-end'>
        <Button size='sm' onClick={() => Api.Auth.signOut()}>
          {translate('Log out')}
        </Button>
      </Row>
      <Row className={flexCenter} style={{ height: '20vh' }}>
        <h2>Scan product tag</h2>
      </Row>
      <Row style={legacyNeeded ? { display: 'none' } : { height: '50vh' }}>
        <Col className={flexCenter}>
          <QrReader
            ref={ref => (reader = ref)}
            className='qr-image-wrapper'
            facingMode='environment'
            onError={handleError}
            onScan={handleScan}
            style={{ width: '25vh' }}
            legacyMode={legacyNeeded}
          />
        </Col>
      </Row>
      <Row className={`${flexCenter} m-4`}>
        {error ? (
          <Button
            color='danger'
            onClick={
              legacyNeeded
                ? () => {
                  ; (reader as any).openImageDialog()
                }
                : undefined
            }
          >
            {error}
          </Button>
        ) : (
            <Button
              onClick={() => {
                if (storeProduct) {
                  navigate!(`${Api.Product._collectionPath}/${storeProduct.uid}`)
                }
              }}
              disabled={!scanResult}
              className='d-flex flex-row align-items-center justify-content-center space-around'
              color={scanResult ? 'success' : 'info'}
            >
              {scanResult ? `${translate('View')} #${scanResult}` : translate('Nothing scanned')}
              {!scanResult && (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: (animationData as any).default,
                  }}
                  height={50}
                  width={50}
                />
              )}
            </Button>
          )}
      </Row>
    </Container>
  )
}
