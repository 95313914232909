import { JMM } from '../jmm_schema'

export namespace JMMImpl {
  export class StoreUserImpl implements JMM.StoreUser {
    email = ''
    name = ''
    admin = false
    credentials: JMM.Credentials = {}
  }
  export class UserCreationDataImpl extends StoreUserImpl
    implements JMM.UserCreationData {
    password = ''
  }

  export class ProductIMPL implements JMM.Product {
    serial = ''
    company = ''
    area = ''
    name = ''
    description = ''
    picture = ''
    log = [] as JMM.RecordEntry[]
  }

  export class CompanyImpl implements JMM.Company {
    name = ''
    areas = [] as string[]
  }
}