import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { translate } from '../components/language_provider'
import { LangToggle } from '../components/lang_toggle'

export const PolicyStatement: FunctionComponent<RouteComponentProps<{
  lang: string
}>> = ({ lang }) => {
  return (
    <>
      <div style={{ margin: '7vw' }}>
        <LangToggle lang={lang} />
      </div>
      <div style={{ margin: '7vw' }}>
        <strong>{translate('Privacy Policy')}</strong>
        <p>{translate('privIntro')}</p>
        <p>{translate('privUsage')}</p>
        <p>{translate('privAgreement')}</p>
        <p>{translate('privTerms')}</p>
        <p>
          <strong>{translate('Information Collection and Use')}</strong>
        </p>
        <p>{translate('privExperience')}</p>
        <div>
          <p>{translate('privThirdParty')}</p>
          <p>{translate('privThirdPartyLinks')}</p>
          <ul>
            <li>
              <a
                href='https://www.google.com/policies/privacy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href='https://firebase.google.com/policies/analytics'
                target='_blank'
                rel='noopener noreferrer'
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href='https://expo.io/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Expo
              </a>
            </li>
          </ul>
        </div>
        <p>
          <strong>{translate('Log Data')}</strong>
        </p>
        <p>{translate('privDataCollection')}</p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>{translate('privCookies')}</p>
        <p>{translate('privCookiesService')}</p>
        <p>
          <strong>{translate('Service Providers')}</strong>
        </p>
        <p>{translate('privServiceProvidersReasons')}</p>
        <ul>
          <li>{translate('To facilitate our Service')};</li>
          <li>{translate('To provide the Service on our behalf')};</li>
          <li>{translate('To perform Service-related services; or')}</li>
          <li>
            {translate('To assist us in analyzing how our Service is used')}.
          </li>
        </ul>
        <p>{translate('privServiceProvidersInfo')}</p>
        <p>
          <strong>{translate('Security')}</strong>
        </p>
        <p>{translate('privSecurityTrust')}</p>
        <p>
          <strong>Links to Other Sites</strong>
        </p>
        <p>{translate('privLinksToSites')}</p>
        <p>
          <strong>{translate('Children’s Privacy')}</strong>
        </p>
        <p>{translate('privChildrens')}</p>
        <p>
          <strong>{translate('Changes to This Privacy Policy')}</strong>
        </p>
        <p>{translate('privPolicyUpdates')}</p>
        <p>{translate('This policy is effective as of')} 2020-05-11</p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>{translate('privContactInfo')} <a href={'mailto:dev@ggo.blue'}>dev@ggo.blue</a></p>
      </div>
    </>
  )
}
