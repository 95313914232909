import React, { FunctionComponent } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { translate } from '../language_provider';

export const Footer: FunctionComponent<{
  onDelete?: () => any
  saveEnabled: boolean
}> = ({ onDelete, saveEnabled }) => (
  <Row>
    <Col>
      {saveEnabled && (
        <Button className='my-4' color='default' outline>
          {translate('Save')}
        </Button>
      )}
    </Col>
    {onDelete && (
      <Col>
        <Button onClick={onDelete} className='my-4' color='danger' outline>
          {translate('Delete')}
        </Button>
      </Col>
    )}
  </Row>
)
