import { isEqual } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import { Field, FormSpy, useFormState } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Badge, Row } from 'reactstrap'
import { UserFormCompanyArea, UserFormValues } from '../../screens/user_form'

export type CompanyRowProps = { company: string; companyIndex: number }
export const CompanyRow: FunctionComponent<CompanyRowProps> = ({
  company,
  companyIndex,
}) => {
  const [isOpen, setOpen] = useState(
    useFormState<UserFormValues>().values.credentials[
      companyIndex
    ].companyAreas.some(({ authorized }) => authorized)
  )
  const toggleOpen = () => setOpen(!isOpen)

  return (
    <div>
      <Row>
        <Field name={`${company}.companyName`}>
          {({ input: { value } }) => (
            <label className='form-control-label' onClick={toggleOpen}>{`${value} ${!isOpen ? '▼' : '▲'}`}</label>
          )}
        </Field>
      </Row>
      <Row>
        {isOpen && (
          <FieldArray<UserFormCompanyArea>
            name={`${company}.companyAreas`}
            isEqual={isEqual}
            companyIndex={companyIndex}
          >
            {({ fields: areas }) =>
              areas.map((area, areaIndex) => (
                <div key={area}>
                  <FormSpy<UserFormValues>>
                    {({ values, form }) => {
                      const { areaName, authorized } = values.credentials[
                        companyIndex
                      ].companyAreas[areaIndex]

                      return (
                        <Badge
                          className='m-1'
                          color={authorized ? 'success' : 'danger'}
                          onClick={() =>
                            form.change(`${area}.authorized`, !authorized)
                          }
                        >
                          {areaName}
                        </Badge>
                      )
                    }}
                  </FormSpy>
                </div>
              ))
            }
          </FieldArray>
        )}
      </Row>
    </div>
  )
}
