import { Router } from '@reach/router'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { Api } from './api'
import { Auth } from './fb_app'
import { JMM } from './jmm_schema'
import { JMMImpl } from './utils/jmm_impl'

import { LoadingScreen } from './components/loading'
import { Home as AdminHome } from './screens/admin_home'
import { CollectionForm } from './screens/collection_form'
import { CompanyForm } from './screens/company_form'
import { Login } from './screens/login'
import { NotFound } from './screens/not_found'
import { Home as PeasantHome } from './screens/peasant_home'
import { PolicyStatement } from './screens/policy_statement'
import { ProductForm } from './screens/product_form'
import { ProductView } from './screens/product_view'
import { UserForm } from './screens/user_form'

import './assets/scss/argon.scss'
import './assets/scss/styles.scss'
import 'react-virtualized/styles.css'
import { TermsConditions } from './screens/terms_conditions'
import { Support } from './screens/support'

// i18n impl
import { IntlContextProvider } from './components/language_provider'

const App: FunctionComponent = props => {
  const [user, setUser] = useState<(JMM.StoreUser & JMM.idd) | null>(null)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    const unsubAuth = Auth.onAuthStateChanged(
      async (authUser: firebase.User | null, error?: firebase.auth.Error) => {
        let storeUser: (JMM.StoreUser & JMM.idd) | null = null

        if (authUser) {
          const { uid } = authUser
          storeUser = await Api.User.read(uid)
        }

        setUser(storeUser)
        setReady(true)
      }
    )
    return function cleanup() {
      unsubAuth()
    }
  }, [])

  return !ready ? (
    <LoadingScreen />
  ) : (
    <IntlContextProvider>
      <Router>
        {user ? (
          user.admin ? (
            <>
              <AdminHome path='/' user={user} />

              <CollectionForm<JMM.StoreUser & JMM.idd>
                path='users'
                api={Api.User}
                entityClass={JMMImpl.StoreUserImpl}
              />
              <UserForm path='users/:uidOrNew' user={user} />

              <CollectionForm<JMM.Product & JMM.idd>
                path='products'
                api={Api.Product}
                entityClass={JMMImpl.ProductIMPL}
              />
              <ProductForm path='products/:uidOrNew' />

              <CollectionForm<JMM.Company & JMM.idd>
                path='companies'
                api={Api.Company}
                entityClass={JMMImpl.CompanyImpl}
              />
              <CompanyForm path='companies/:uidOrNew' />
            </>
          ) : (
            <>
              <PeasantHome path='/' user={user} />
              <ProductView path='/products/:uid' />
            </>
          )
        ) : (
          <Login path='/' />
        )}

        <PolicyStatement path='/privacy' />
        <PolicyStatement path='/privacy/:lang' />
        <TermsConditions path='/terms' />
        <TermsConditions path='/terms/:lang' />
        <Support path='/support' />
        <Support path='/support/:lang' />

        <NotFound path='404' default />
      </Router>
    </IntlContextProvider>
  )
}

export default App
