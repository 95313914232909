import React, { Component } from 'react'
import { Badge, Button, Col, Row, Table } from 'reactstrap'

import update from 'immutability-helper'
import { isEqual } from 'lodash'

import { JMM } from '../../jmm_schema'

import {
  pathFromTimeSpan,
  ProductForm,
  ProductFormLogEntry,
} from '../../screens/product_form'
import { RecordForm } from './record_form'

import { translate } from '../language_provider'

interface LogFormProps {
  log: ProductFormLogEntry[]
  productForm: ProductForm
}

const compareRecords = (
  a: { timeSpan: JMM.TimeSpan },
  b: { timeSpan: JMM.TimeSpan }
) => a.timeSpan.from.nanoseconds - b.timeSpan.from.nanoseconds

export class LogForm extends Component<LogFormProps> {
  originalLog: ProductFormLogEntry[] | null = null

  componentWillReceiveProps(nextProps: LogFormProps) {
    if (!isEqual(nextProps, this.props)) {
      this.setState({ log: nextProps.log })
    }
  }

  render() {
    const { log, productForm } = this.props
    return (
      <>
        <RecordForm
          onSave={record =>
            productForm.setState(prevState =>
              update(prevState, {
                form: {
                  log: {
                    $push: [record],
                  },
                },
              })
            )
          }
        />
        <Row className='flex-grow-1 overflow-scroll'>
          <Col>
            <Table className='align-items-center' responsive>
              <thead className='thead-light'>
                <tr>
                  <th>{translate('Name')}</th>
                  <th>{translate('From')}</th>
                  <th>{translate('To')}</th>
                  <th>{translate('Attatchments')}</th>
                </tr>
              </thead>
              <tbody>
                {log
                  .sort(compareRecords)
                  .map(({ name, timeSpan: { to, from }, attachments }, idx) => (
                    <tr
                      key={`${name}|${to}|${from}|${attachments?.length || 0}`}
                    >
                      <td>{name}</td>
                      <td>{`${from.toDate().toLocaleDateString()}`}</td>
                      <td>{`${to.toDate().toLocaleDateString()}`}</td>
                      <td className='d-flex flex-wrap'>
                        {/* TODO support addition per record? */}
                        {attachments &&
                          attachments.map(
                            // TODO onClick opens modal to download/delete
                            att => (
                              <Badge
                                key={`${
                                  (att as JMM.Attachment).url ||
                                  (att as File).lastModified
                                }`}
                                color='primary'
                              >
                                {att.name}
                              </Badge>
                            )
                          )}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            productForm.setState(prevState => {
                              const log = prevState.form.log[idx]
                              return update(prevState, {
                                form: { log: { $splice: [[idx, 1]] } },
                                deletedAttachments: {
                                  $push:
                                    log.attachments?.map(attachment => ({
                                      file: attachment as JMM.Attachment,
                                      path: pathFromTimeSpan(log.timeSpan),
                                    })) || [],
                                },
                              })
                            })
                          }
                          close
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    )
  }
}
