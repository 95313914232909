import {
  Auth as FbAuth,
  DocumentReference,
  Firestore,
  Functions,
  Storage as FbStorage,
  TaskEvent,
} from './fb_app'
import { JMM } from './jmm_schema'
import { firestore } from 'firebase'

function failWith(onFailReturn: any = []) {
  return (_: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value
    descriptor.value = async function () {
      try {
        const result = await originalMethod.apply(this, arguments)
        return result
      } catch (e) {
        console.warn(`store: ${propertyKey}(${[...arguments]}) failed: ${e}`)
        return onFailReturn
      }
    }

    return descriptor
  }
}

export abstract class EntityApi<T> {
  public abstract _collectionPath: string
  public abstract async create(
    creationData: Omit<T, 'uid'>
  ): Promise<void | firestore.DocumentReference<T>>
  public abstract async read(uid: string): Promise<T>

  public abstract async readAll(): Promise<T[]>
  public abstract async readAfter(
    ref: firestore.QueryDocumentSnapshot<T>,
    limit: number
  ): Promise<firestore.QueryDocumentSnapshot<T>[]>

  public abstract async readLimit(
    limit: number
  ): Promise<firestore.QueryDocumentSnapshot<T>[]>
  public abstract async update({
    uid,
    ...updateData
  }: JMM.idd & Partial<T>): Promise<void>
  public abstract async delete(uid: string): Promise<void>
}

export namespace Api {
  export class Auth {
    public static async signIn(mail: string, password: string) {
      return FbAuth.signInWithEmailAndPassword(mail, password)
    }

    public static async signOut() {
      await FbAuth.signOut()
    }
  }

  export class Storage {
    static upload = async (
      path: string,
      file: File,
      onStateChange: (snapshot: any) => void = () => {
        return
      },
      onError: (error: any) => void = e => {
        throw e
      },
      onSuccess: (downloadUrl: string) => void = () => {
        return
      }
    ) =>
      new Promise((resolve, reject) => {
        const sotrageRef = FbStorage.ref()
        const newChildRef = sotrageRef.child(path)
        const uploadTask = newChildRef.put(file)
        uploadTask.on(
          TaskEvent.STATE_CHANGED,
          snapshot => onStateChange(snapshot),
          error => {
            onError(error)
            reject()
          },
          async () => {
            const url = await newChildRef.getDownloadURL()
            onSuccess(url)
            resolve(url)
          }
        )
      })

    static async remove(path: string) {
      const sotrageRef = FbStorage.ref()
      const child = sotrageRef.child(path)
      return child.delete()
    }

    static removeMany = (paths: string[]) =>
      Promise.all(paths.map(path => Storage.remove(path)))
  }

  class Store {
    public static async getCollIddDocs<T>(
      path: string
    ): Promise<(T & JMM.idd)[]> {
      const coll = await Firestore.collection(path).get()
      const result: (T & JMM.idd)[] = []

      for (const doc of coll.docs) {
        const data = { ...(doc.data() as object) }
        result.push({ ...data, uid: doc.id } as T & JMM.idd)
      }

      return result
    }

    public static async getCollIddDocsLimit<T>(
      path: string,
      limit = 20
    ): Promise<firestore.QueryDocumentSnapshot<T>[]> {
      const coll = await Firestore.collection(path).limit(limit).get()

      const snapshots: firestore.QueryDocumentSnapshot<T>[] = []
      for (const doc of coll.docs) {
        snapshots.push(doc as firestore.QueryDocumentSnapshot<T>)
      }

      return snapshots
    }

    public static async getCollIddDocsAfter<T>(
      path: string,
      after: firestore.QueryDocumentSnapshot<T>,
      limit = 20
    ): Promise<firestore.QueryDocumentSnapshot<T>[]> {
      const coll = await Firestore.collection(path)
        .startAfter(after)
        .limit(limit)
        .get()

      const snapshots: firestore.QueryDocumentSnapshot<T>[] = []
      for (const doc of coll.docs) {
        snapshots.push(doc as firestore.QueryDocumentSnapshot<T>)
      }

      return snapshots
    }

    @failWith(null)
    public static async getIddDoc<T>(path: string): Promise<T & JMM.idd> {
      const docRef = await Firestore.doc(path).get()
      if (!docRef.exists) throw new Error(`Document at ${path}`)
      const document = docRef.data()
      return { ...document, uid: docRef.id } as T & JMM.idd
    }

    public static async deleteDoc(path: string) {
      return Firestore.doc(path).delete()
    }

    public static async setItem<T>(item: T, collection: string, uid?: string) {
      if (!uid) {
        return Firestore.collection(collection).add(item)
      }
      return Firestore.collection(collection)
        .doc(uid)
        .set(item, { merge: true })
    }
  }

  const StoreEntityApi = <T extends JMM.idd>(collectionPath: string) => {
    // tslint:disable-next-line: no-shadowed-variable
    class StoreEntityApi {
      public static _collectionPath: string = collectionPath

      public static async create(
        creationData: Omit<T, 'uid'>
      ): Promise<void | DocumentReference<T>> {
        return Store.setItem(creationData, this._collectionPath) as Promise<
          void
        >
      }
      @failWith(undefined)
      public static async read(uid: string): Promise<T> {
        return Store.getIddDoc(`${this._collectionPath}/${uid}`)
      }

      public static async readAll(): Promise<T[]> {
        return Store.getCollIddDocs<T>(`${this._collectionPath}`)
      }

      public static async readLimit(
        limit: number
      ): Promise<firestore.QueryDocumentSnapshot<T>[]> {
        return Store.getCollIddDocsLimit<T>(`${this._collectionPath}`, limit)
      }

      public static async readAfter(
        ref: firestore.QueryDocumentSnapshot<T>,
        limit: number
      ): Promise<firestore.QueryDocumentSnapshot<T>[]> {
        return Store.getCollIddDocsAfter<T>(
          `${this._collectionPath}`,
          ref,
          limit
        )
      }

      public static async update({
        uid,
        ...updateData
      }: JMM.idd & Partial<T>): Promise<void> {
        return Store.setItem(updateData, this._collectionPath, uid) as Promise<
          void
        >
      }

      public static async delete(uid: string): Promise<void> {
        return Store.deleteDoc(`${this._collectionPath}/${uid}`)
      }
    }
    return StoreEntityApi
  }

  const addUser = Functions.httpsCallable('addUser')
  const updateUser = Functions.httpsCallable('updateUser')
  const deleteUser = Functions.httpsCallable('deleteUser')

  export class User extends StoreEntityApi<JMM.StoreUser & JMM.idd>('users') {
    public static async create({
      email,
      password,
      ...user
    }: JMM.UserCreationData) {
      const userCred = await addUser({ email, password })

      if (!userCred.data) throw new Error('Error creating auth user')

      const {
        data: { uid },
      } = userCred
      return Store.setItem(
        { email, ...user },
        this._collectionPath,
        uid
      ) as Promise<void>
    }

    public static async update({
      uid,
      email,
      password,
      ...userData
    }: JMM.UserUpdateData) {
      const passwordUpdate = password ? { password } : {}
      const emailUpdate = email ? { email } : {}

      if (email || password)
        await updateUser({ uid, ...emailUpdate, ...passwordUpdate })

      const emailData = email ? { email } : {}
      return super.update({ uid, ...userData, ...emailData })
    }

    public static async delete(uid: string) {
      await deleteUser({ uid })
      return super.delete(uid)
    }
  }

  export class Product extends StoreEntityApi<JMM.Product & JMM.idd>(
    'products'
  ) {
    @failWith(undefined)
    public static async readWithSerial(serialNo: string) {
      const queryResult = await Firestore.collection(this._collectionPath)
        .where('serial', '==', serialNo)
        .limit(1)
        .get()
      const doc = queryResult.docs[0]
      return { ...doc.data(), uid: doc.id } as JMM.Product & JMM.idd
    }
  }

  export class Company extends StoreEntityApi<JMM.Company & JMM.idd>(
    'companies'
  ) {}
}
