import { Link, RouteComponentProps } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'

import { NavBar } from '../components/navbar'

import { JMM } from '../jmm_schema'

import { translate } from '../components/language_provider'

interface HomeProps {
  user: JMM.StoreUser & JMM.idd
}

export const Home: FunctionComponent<RouteComponentProps<HomeProps>> = ({
  user,
}) => {
  return (
    <>
      <NavBar user={user} />
      <Container fluid>
        <Row style={{ height: '30vh' }} />
        <Row style={{ height: '50vh' }}>
          <Link
            style={{
              color: 'white',
              backgroundColor: 'rgb(244, 95, 74)',
              fontSize: '2em',
            }}
            className='d-flex flex-fill align-items-center justify-content-center hover-darken rounded m-3'
            to='users'
          >
            <Col className='text-center'> {translate('Users')} </Col>
          </Link>
          <Link
            style={{
              color: 'white',
              backgroundColor: 'rgb(2, 56, 89)',
              fontSize: '2em',
            }}
            className='d-flex flex-fill align-items-center justify-content-center hover-darken rounded m-3'
            to='products'
          >
            <Col className='text-center'> {translate('Products')} </Col>
          </Link>
          <Link
            style={{
              color: 'white',
              backgroundColor: 'rgb(123, 180, 133)',
              fontSize: '2em',
            }}
            className='d-flex flex-fill align-items-center justify-content-center hover-darken rounded m-3'
            to='companies'
          >
            <Col className='text-center'> {translate('Companies')} </Col>
          </Link>
        </Row>
      </Container>
    </>
  )
}
