import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap'

import { diff } from 'deep-object-diff'
import update from 'immutability-helper'
import { cloneDeep, isEqual } from 'lodash'

import { Api } from '../api'
import { JMM } from '../jmm_schema'
import { JMMImpl } from '../utils/jmm_impl'
import { Validation } from '../utils/validation'

import '../assets/css/collection.css'
import '../assets/css/react-autosuggest.css'

import Lottie from 'react-lottie'
import * as animationData from '../assets/animations/726-ice-cream-animation.json'
import { LogForm } from '../components/forms/log_form'
import { LoadingScreen } from '../components/loading'
import { NavBar } from '../components/navbar'

import { translate } from '../components/language_provider'

interface ProductFormProps {
  uidOrNew: 'new' | string
}

export type ProductFormLogEntry = Omit<JMM.RecordEntry, 'attachments'> & {
  attachments?: (JMM.Attachment | File)[]
}
export type DeletedAttachment = { file: JMM.Attachment; path: string }
type ProductFormData = Omit<JMM.Product, 'log'> & { log: ProductFormLogEntry[] }

interface ProductFormErrors {
  serial?: string
  company?: string
  area?: string
  others?: string[]
}

interface ProductFormState {
  form: ProductFormData
  deletedAttachments: DeletedAttachment[]

  companySuggestions: string[]
  areaSuggestions: string[]
  errors?: ProductFormErrors

  loadingState: string
  ready: boolean
}

// TODO move into utils file?
const NEW = 'new'
export const pathFromTimeSpan = ({ from, to }: JMM.TimeSpan) =>
  `${from.nanoseconds}_${to.nanoseconds}`
type StrMap<V> = { [key: string]: V }

export class ProductForm extends Component<
  RouteComponentProps<ProductFormProps>,
  ProductFormState
  > {
  state = {
    form: new JMMImpl.ProductIMPL() as ProductFormData,
    deletedAttachments: [] as DeletedAttachment[],

    companySuggestions: [],
    areaSuggestions: [],
    company: null,

    errors: { others: [] } as ProductFormErrors,
    ready: false,
    loadingState: '',
  }

  availableCompanies: StrMap<string[]> = {}
  originalForm: ProductFormData | null = null
  logRef: LogForm | null = null

  async componentDidMount() {
    const { uidOrNew, navigate } = this.props

    let { form } = this.state
    if (uidOrNew && uidOrNew !== 'new') {
      const storeProduct = await Api.Product.read(uidOrNew)
      if (!storeProduct) {
        navigate!('/404')
        return
      }

      const { uid: _, ...product } = storeProduct
      form = { ...form, ...product }
    }

    this.availableCompanies = (await Api.Company.readAll()).reduce<
      StrMap<string[]>
    >((acc, { name, areas }) => {
      acc[name] = areas
      return acc
    }, {})
    this.setState(() => {
      this.originalForm = cloneDeep(form) as ProductFormData
      return { form, ready: true }
    })
  }

  suggestionsOf(hint: string, options: string[] = []) {
    const inputValue = hint.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : options.filter(
        option => option.toLowerCase().slice(0, inputLength) === inputValue
      )
  }

  render() {
    const {
      form: { serial, company, area, name, description, log },
      companySuggestions,
      areaSuggestions,
      ready,
      loadingState,
      errors,
    } = this.state
    const { uidOrNew } = this.props
    const CREATING = uidOrNew === Validation.NEW
    if (!ready) return <LoadingScreen />
    return (
      <>
        <NavBar />
        <Container fluid>
          <Row className='collection-controls'>
            <Col md={12}>
              <h1>{translate(`${CREATING ? 'New' : 'Edit'} product`)}</h1>
            </Col>
          </Row>

          <Form onSubmit={event => this.handleSubmit(event)}>
            <Row>
              <Col>
                <h6 className='heading-small text-muted mb-4'>
                  {' '}
                  {translate('Product information')}
                </h6>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='input-serial'
                      >
                        {' '}
                        {translate('Serial Number')} | #{' '}
                      </label>
                      <Input
                        name='serial'
                        type='text'
                        value={serial}
                        onChange={event => this.handleFormChange(event)}
                        className='form-control-alternative'
                        id='input-serial'
                        placeholder='AAA-00-000'
                      />
                    </FormGroup>
                  </Col>

                  <Col md={8}>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='input-name'
                      >
                        {' '}
                        {translate('Name')}{' '}
                      </label>
                      <Input
                        name='name'
                        type='text'
                        value={name}
                        onChange={event => this.handleFormChange(event)}
                        className='form-control-alternative'
                        id='input-name'
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='input-company'
                      >
                        {' '}
                        {translate('Company')}{' '}
                      </label>
                      <Autosuggest
                        // alwaysRenderSuggestions
                        suggestions={companySuggestions}
                        inputProps={{
                          name: 'company',
                          value: company,
                          type: 'text',
                          className: 'form-control-alternative form-control',
                          id: 'input-company',
                          onChange: (event: any) =>
                            this.handleFormChange(event),
                        }}
                        onSuggestionsFetchRequested={({ value }) =>
                          this.setState({
                            companySuggestions: this.suggestionsOf(
                              value,
                              Object.keys(this.availableCompanies)
                            ),
                          })
                        }
                        onSuggestionsClearRequested={() =>
                          this.setState({ companySuggestions: [] })
                        }
                        getSuggestionValue={companyName => companyName}
                        renderSuggestion={companyName => (
                          <div>{companyName}</div>
                        )}
                        onSuggestionSelected={(_, { suggestion }) =>
                          suggestion !== company
                            ? this.setState(p =>
                              update(p, {
                                form: {
                                  company: { $set: suggestion },
                                  area: { $set: '' },
                                },
                              })
                            )
                            : null
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='input-area'
                      >
                        {' '}
                        {translate('Area')}{' '}
                      </label>
                      <Autosuggest
                        // alwaysRenderSuggestions
                        suggestions={areaSuggestions}
                        inputProps={{
                          name: 'area',
                          value: area,
                          type: 'text',
                          onChange: event => this.handleFormChange(event),
                          className: 'form-control-alternative form-control',
                          id: 'input-area',
                        }}
                        onSuggestionsFetchRequested={({ value }) =>
                          this.setState({
                            areaSuggestions: this.suggestionsOf(
                              value,
                              company ? this.availableCompanies[company] : []
                            ),
                          })
                        }
                        onSuggestionsClearRequested={() =>
                          this.setState({ areaSuggestions: [] })
                        }
                        getSuggestionValue={areaName => areaName}
                        renderSuggestion={areaName => <div>{areaName}</div>}
                        onSuggestionSelected={(_, { suggestion }) =>
                          this.setState(p =>
                            update(p, { form: { area: { $set: suggestion } } })
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label className='form-control-label'>{translate('Description')}</label>
                      <Input
                        name='description'
                        value={description}
                        type='textarea'
                        onChange={event => this.handleFormChange(event)}
                        rows='2'
                        className='form-control-alternative'
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <hr className='my-4' />
                <h6 className='heading-small text-muted mb-4'>{translate('Product log')}</h6>
                <Row></Row>

                <LogForm
                  log={log}
                  ref={ref => (this.logRef = ref)}
                  productForm={this}
                />
                {loadingState && (
                  <div className='d-flex flex-fill align-items-center justify-content-center'>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: (animationData as any).default,
                      }}
                      height={100}
                      width={100}
                    />
                    <p>{loadingState}</p>
                  </div>
                )}
                {(errors.others?.length ||
                  errors.serial ||
                  errors.company ||
                  errors.area) && (
                    <Alert color='danger'>{JSON.stringify(errors)}</Alert>
                  )}
                {!isEqual(this.state.form, this.originalForm) && (
                  <Button className='my-4' color='default' outline>
                    {translate('Save')}
                  </Button>
                )}
                {uidOrNew !== NEW && (
                  <Button
                    onClick={() => this.delete()}
                    className='my-4'
                    color='danger'
                    outline
                  >
                    {translate('Delete')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </>
    )
  }

  async delete() {
    const { uidOrNew, navigate } = this.props
    const { form } = this.state

    try {
      await Api.Product.delete(uidOrNew!)
      await Api.Storage.removeMany(
        this.originalForm!.log.reduce<string[]>(
          (acc, { attachments, timeSpan }) => {
            if (!attachments) return acc
            acc.push(
              ...attachments!.map<string>(
                att =>
                  `pdf/${form.serial}/${pathFromTimeSpan(timeSpan)}/${att.name}`
              )
            )
            return acc
          },
          []
        )
      )
      navigate!(`/${Api.Product._collectionPath}`)
    } catch (e) {
      this.setOtherError(`${e.message || e}`)
    }
  }

  handleFormChange({
    currentTarget: { name: targetName, value: targetValue, type, checked },
  }: React.FormEvent<HTMLInputElement>) {
    if (!targetName) return
    this.setState(prevState =>
      update(prevState, { form: { [targetName]: { $set: targetValue } } })
    )
  }

  setOtherError(e: any) {
    this.setState(prev => {
      if (!prev.errors?.others)
        return update(prev, {
          errors: { others: { $set: [`${e}`] } },
          loadingState: { $set: '' },
        })
      return update(prev, {
        errors: { others: { $push: [`${e}`] } },
        loadingState: { $set: '' },
      })
    })
  }

  async handleSubmit(_: React.FormEvent<HTMLFormElement>) {
    _.preventDefault()
    const {
      form: { serial, company, area },
    } = this.state
    this.setState(p =>
      update(p, { errors: { $set: {} }, loadingState: { $set: 'Saving' } })
    )
    if (Validation.isSerial(serial)) {
      this.setState(prev =>
        update(prev, {
          errors: { serial: { $set: 'Invalid serial' } },
          loadingState: { $set: '' },
        })
      )
      return
    }
    if (!(company in this.availableCompanies)) {
      this.setState(prev =>
        update(prev, {
          errors: { company: { $set: 'Invalid company' } },
          loadingState: { $set: '' },
        })
      )
      return
    }
    if (
      !this.availableCompanies[company].find(
        candidateArea => candidateArea === area
      )
    ) {
      this.setState(prev =>
        update(prev, {
          errors: { area: { $set: 'Invalid area' } },
          loadingState: { $set: '' },
        })
      )
      return
    }

    const form = cloneDeep(this.state.form)
    const originalForm = this.originalForm as ProductFormData

    // TODO move all this logic into Api or product_api.ts
    // write storage
    const newFiles = form.log.reduce<{ file: File; path: string }[]>(
      (acc, cur) => {
        if (cur.attachments)
          acc.push(
            ...(cur.attachments.filter(f => f instanceof File) as File[]).map(
              file => ({
                file,
                path: `pdf/${form.serial}/${pathFromTimeSpan(cur.timeSpan)}/${
                  file.name
                  }`,
              })
            )
          )
        return acc
      },
      []
    )

    if (newFiles.length) {
      this.setState({ loadingState: 'Uploading files' })
      let urls
      try {
        const uploads = newFiles.map(({ file, path }) =>
          Api.Storage.upload(`pdf/${form.serial}/${path}/${file.name}`, file)
        )
        urls = await Promise.all(uploads)
      } catch (e) {
        console.error(e)
        this.setOtherError(`${e.message || e}`)
        return
      }

      // update Files into Attachments
      let urlIdx = 0
      for (const { attachments } of form.log.filter(e => !!e.attachments)) {
        for (const i in attachments!) {
          if (attachments[i] instanceof File)
            attachments[i] = {
              name: attachments[i].name,
              url: urls[urlIdx++],
            } as JMM.Attachment
        }
      }
    }

    // strip form
    // write firestore
    try {
      let minimalFormData: Partial<JMM.Product> = form
      if (this.props.uidOrNew && this.props.uidOrNew !== NEW) {
        const { log, ...textFields } = form
        const { log: originalLog, ...originalTextFields } = originalForm
        minimalFormData = isEqual(log, originalLog)
          ? { ...diff(originalTextFields, textFields) }
          : {
            ...diff(originalTextFields, textFields),
            log: log as JMM.RecordEntry[],
          }
        await Api.Product.update({
          ...minimalFormData,
          uid: this.props.uidOrNew,
        })
      } else {
        await Api.Product.create({ ...(form as JMM.Product) })
      }
    } catch (e) {
      console.error(e)
      this.setOtherError(`${e.message || e}`)
      return
    }

    // delete old
    try {
      if (this.state.deletedAttachments.length)
        await Promise.all(
          this.state.deletedAttachments.map(({ file: { name }, path }) =>
            Api.Storage.remove(`pdf/${form.serial}/${path}/${name}`)
          )
        )
    } catch (e) {
      console.error(e)
      this.setOtherError(`${e.message || e}`)
      return
    }

    this.props.navigate!(`/${Api.Product._collectionPath}`)
  }
}
