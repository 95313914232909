import { RouteComponentProps } from '@reach/router'
import React, { Component } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap'

import update from 'immutability-helper'
import { cloneDeep, isEqual } from 'lodash'

import { Api } from '../api'
import { LoadingScreen } from '../components/loading'
import { NavBar } from '../components/navbar'
import { JMM } from '../jmm_schema'
import { JMMImpl } from '../utils/jmm_impl'
import { Validation } from '../utils/validation'

import '../assets/css/collection.css'

import { translate } from '../components/language_provider'

const NEW = 'new'

export interface CompanyFormProps {
  uidOrNew: string
}
export type CompanyFormState = JMM.Company & {
  newAreaName: string
  loading: boolean
  errors?: {
    name?: string
    area?: string
    other?: string[]
  }
}
export class CompanyForm extends Component<
  RouteComponentProps<CompanyFormProps>,
  CompanyFormState
  > {
  state = {
    ...new JMMImpl.CompanyImpl(),
    newAreaName: '',
    loading: true,
    errors: {},
  }

  originalState: JMM.Company | null = null

  async componentDidMount() {
    const { uidOrNew, navigate } = this.props

    let state = this.state
    if (uidOrNew && uidOrNew !== 'new') {
      const storeCompany = await Api.Company.read(uidOrNew)
      if (!storeCompany) {
        navigate!('/404')
        return
      }
      state = { ...state, ...storeCompany }
    }

    this.setState(() => {
      this.originalState = cloneDeep(state)
      return { ...state, loading: false }
    })
  }

  render() {
    const { name, newAreaName, areas, errors, loading } = this.state
    const { uidOrNew } = this.props
    const CREATING = uidOrNew === Validation.NEW
    if (loading) return <LoadingScreen />
    return (
      <>
        <NavBar />
        <Container fluid>
          <Row className='collection-controls'>
            <Col md={12}>
              <h1>{translate(`${CREATING ? 'New' : 'Edit'} company`)}</h1>
            </Col>
          </Row>

          <Form onSubmit={event => this.handleSubmit(event)}>
            <h6 className='heading-small text-muted mb-4'>
              {translate('Company information')}
            </h6>
            <FormGroup>
              <label className='form-control-label' htmlFor='input-name'>
                {translate('Name')}
              </label>
              <Input
                name='name'
                type='text'
                value={name}
                onChange={({ currentTarget: { value: companyName } }) =>
                  this.setState({ name: companyName })
                }
                className='form-control-alternative'
                id='input-name'
              />
            </FormGroup>

            <hr className='my-4' />
            <h6 className='heading-small text-muted mb-4'>{translate('Company Areas')}</h6>
            <Row>
              <Col md={8}>
                <label className='form-control-label'>{translate('Area name')}</label>
                <Input
                  name='newAreaName'
                  type='text'
                  value={newAreaName}
                  autoComplete=''
                  onChange={({ currentTarget: { value } }) =>
                    this.setState({ newAreaName: value })
                  }
                  className='form-control-alternative'
                />
              </Col>
              <Col md={4}>
                <FormGroup className='collection-form'>
                  <Button
                    onClick={() => {
                      if (
                        newAreaName &&
                        !areas.find(area => area === newAreaName)
                      ) {
                        this.setState(prev => {
                          if ((this.state.errors as any).area)
                            delete (this.state.errors as any).area
                          return update(prev, {
                            areas: { $push: [newAreaName] },
                            newAreaName: { $set: '' },
                          })
                        })
                      } else {
                        this.setState(prev =>
                          update(prev, {
                            errors: { area: { $set: 'Invalid area' } },
                          })
                        )
                      }
                    }}
                    className='my-4'
                    color='default'
                    outline
                  >
                    {translate('Add')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Row className='flex-grow-1 overflow-scroll'>
              <Col>
                <Table className='align-items-center' responsive>
                  <thead className='thead-light'>
                    <tr>
                      <th>{translate('Area name')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {areas.sort().map((area, idx) => (
                      <tr key={`${area}|${idx}`}>
                        <td>{area}</td>
                        <td>
                          <Button
                            onClick={() =>
                              this.setState(prev =>
                                update(prev, { areas: { $splice: [[idx, 1]] } })
                              )
                            }
                            close
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            {!Validation.isObjectEmpty(errors) && (
              <Alert color='danger'>{JSON.stringify(errors)}</Alert>
            )}
            {(!isEqual(this.state.areas, this.originalState!.areas) ||
              name !== this.originalState!.name) && (
                <Button className='my-4' color='default' outline>
                  {translate('Save')}
                </Button>
              )}
            {uidOrNew !== NEW && (
              <Button
                onClick={() => this.delete()}
                className='my-4'
                color='danger'
                outline
              >
                {translate('Delete')}
              </Button>
            )}
          </Form>
        </Container>
      </>
    )
  }

  setOtherError(e: any) {
    this.setState(prev => {
      if (!prev.errors?.other)
        return update(prev, { errors: { other: { $set: [`${e}`] } } })
      return update(prev, { errors: { other: { $push: [`${e}`] } } })
    })
  }

  async delete() {
    const { navigate, uidOrNew: uid } = this.props
    try {
      await Api.Company.delete(uid!)
      navigate!(`/${Api.Company._collectionPath}`)
    } catch (e) {
      this.setOtherError(`${e}`)
    }
  }

  async handleSubmit(_: React.FormEvent<HTMLFormElement>) {
    _.preventDefault()
    const { name, areas } = this.state
    const { uidOrNew: uid, navigate } = this.props
    this.setState({ errors: {} })
    if (!name) {
      this.setState(p =>
        update(p, { errors: { name: { $set: 'Invalid name' } } })
      )
      return
    }
    if (!areas || !areas.length) {
      this.setState(p =>
        update(p, {
          errors: { name: { $set: 'A company without areas is useless' } },
        })
      )
      return
    }
    try {
      if (this.props.uidOrNew && this.props.uidOrNew !== NEW) {
        await Api.Company.update({ name, areas, uid: uid! })
      } else {
        await Api.Company.create({ name, areas })
      }
      navigate!(`/${Api.Company._collectionPath}`)
    } catch (e) {
      this.setOtherError(`${e}`)
    }
  }
}
