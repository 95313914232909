
export namespace Validation {
  export type Validator = (value: any) => string | undefined
  export const NEW = 'new'

  export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  export const serialPattern = /^[\d\w]{3,}-[\d\w]{2,}-[\d\w]{4,}$/

  export const isObjectEmpty = (object: object): boolean =>
    !Object.keys(object).length || Object.values(object).every(value => !value)

  const check = (predicate: boolean, message: string = '') =>
    predicate ? undefined : message

  export const required = (message: string = 'this field') => (value: any) =>
    check(value, `${message} is required`)

  export const isOfLength = (length: number) => (value: string) =>
    check(value.length > length, `must be of length ${length}`)

  export const isEmail: Validator = (candidate: string) =>
    check(emailPattern.test(candidate), 'not a valid email')

  export const isSerial: Validator = (candidate: string) =>
    check(serialPattern.test(candidate), 'not a valid serial')

  export const all = (...validators: Validator[]) => (value: any) =>
    validators.reduce<string | undefined>(
      (error, validator) => error || validator(value),
      undefined
    )

  export const upperCaseFirst = (str: string) =>
    str[0].toUpperCase() + str.slice(1)
}
