import React, { FunctionComponent, useContext } from 'react'
import { Button } from 'reactstrap'
import { Context } from '../components/language_provider'

export const LangToggle: FunctionComponent<{ lang?: string }> = ({ lang }) => {
  const context = useContext(Context)
  if (lang && lang === 'es') context.selectLang('es-MX')
  if (lang && lang === 'en') context.selectLang('en-US')
  return (
    <>
      <Button
        disabled={context.locale.substring(0, 2) === 'en'}
        onClick={() => context.selectLang('en-US')}
      >
        EN
      </Button>
      <Button
        disabled={context.locale.substring(0, 2) === 'es'}
        onClick={() => context.selectLang('es-MX')}
      >
        ES
      </Button>
    </>
  )
}
