import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent, useEffect, useState } from 'react'

import { deleteValue } from '../fb_app'

import arrayMutators from 'final-form-arrays'
import { Field as FinalField, Form as FinalFrom } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { LoadingScreen } from '../components/loading'
import { NavBar } from '../components/navbar'
import { JMM } from '../jmm_schema'

import '../assets/css/collection.css'

import { diff } from 'deep-object-diff'
import { Config, FORM_ERROR } from 'final-form'
import { cloneDeep, isEqual } from 'lodash'

import Toggle from 'react-toggle'

import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row,
} from 'reactstrap'
import { Api } from '../api'
import { Footer } from '../components/forms/form_footer'
import { TextField } from '../components/forms/text_field'
import { JMMImpl } from '../utils/jmm_impl'
import { Validation } from '../utils/validation'

import { CompanyRow } from '../components/forms/company_row'

import { translate } from '../components/language_provider'

type UserFormProps = {
  uidOrNew: 'new' | string
  user?: (JMM.StoreUser & JMM.idd) | undefined
}

export type UserFormCompanyArea = { areaName: string; authorized: boolean }
export type UserFormCompany = {
  companyName: string
  companyAreas: UserFormCompanyArea[]
}
export type UserFormValues = {
  email: string
  password: string
  passwordConfirm: string
  name: string
  admin: boolean
  credentials: UserFormCompany[]
}
type UserFormSubmit = Config<UserFormValues>['onSubmit']

const credentialsToForm: (
  credentials: JMM.Credentials,
  availableCompanies: JMM.Company[]
) => UserFormCompany[] = (
  credentials: JMM.Credentials,
  availableCompanies?: JMM.Company[]
) =>
  availableCompanies
    ? availableCompanies.map(({ name: companyName, areas: companyAreas }) => ({
        companyName,
        companyAreas: companyAreas.map(areaName => ({
          areaName,
          authorized: !!(
            credentials &&
            credentials[companyName] &&
            credentials[companyName][areaName]
          ),
        })),
      }))
    : Object.keys(credentials).map(companyName => ({
        companyName,
        companyAreas: Object.keys(credentials[companyName]).map(areaName => ({
          areaName,
          authorized: true,
        })),
      }))

const formToCredentials: (
  formCredentials: UserFormCompany[]
) => JMM.Credentials = (formCredentials: UserFormCompany[]) =>
  formCredentials.reduce<JMM.Credentials>(
    (credentials, { companyName, companyAreas }) => {
      credentials[companyName] = companyAreas.reduce<JMM.StringSet>(
        (set, { areaName, authorized }) => {
          set[areaName] = authorized
          return set
        },
        {}
      )
      return credentials
    },
    {}
  )

export const UserForm: FunctionComponent<RouteComponentProps<
  UserFormProps
>> = ({ uidOrNew, user, navigate }) => {
  const CREATING = uidOrNew === Validation.NEW
  const [loading, setLoading] = useState(true)
  const [initialFormValues, setInitialFormValues] = useState({
    ...new JMMImpl.UserCreationDataImpl(),
    passwordConfirm: '',
    credentials: [] as UserFormCompany[],
  })
  const [passwordSectionShown, setPasswordSectionShown] = useState(CREATING)

  useEffect(() => {
    ;(async () => {
      let freshFormState
      const availableCompanies = await Api.Company.readAll()
      const freshUserForm = {
        ...new JMMImpl.UserCreationDataImpl(),
        passwordConfirm: '',
        credentials: [] as UserFormCompany[],
      }
      if (!CREATING) {
        const storeUser = await Api.User.read(uidOrNew!)
        if (!storeUser) navigate!('/404')
        freshFormState = {
          ...freshUserForm,
          ...storeUser,
          credentials: credentialsToForm(
            storeUser.credentials || {},
            availableCompanies
          ),
        }
      } else {
        freshFormState = {
          ...freshUserForm,
          credentials: credentialsToForm({}, availableCompanies),
        }
      }
      setInitialFormValues(freshFormState)
      setLoading(false)
    })()
  }, [CREATING, navigate, uidOrNew])

  const onDelete = async () => {
    setLoading(true)

    try {
      await Api.User.delete(uidOrNew!)
      navigate!(`/${Api.User._collectionPath}`)
    } catch (e) {
      // TODO: inject into form?
      setLoading(false)
      console.error(e)
    }
  }

  const onSubmit: UserFormSubmit = async formValues => {
    setLoading(true)
    const userData = {
      ...formValues,
      credentials: formToCredentials(formValues.credentials),
    }
    delete userData.passwordConfirm

    try {
      if (CREATING) {
        await Api.User.create(userData)
      } else {
        const credentialsStrip = (cred: JMM.Credentials) => {
          const strippedCredentials = cloneDeep(cred)
          for (const companyName of Object.keys(cred)) {
            if (
              Object.keys(strippedCredentials[companyName]).every(
                areaName => strippedCredentials[companyName][areaName] === false
              )
            ) {
              strippedCredentials[companyName] = deleteValue
              continue
            }

            for (const areaName of Object.keys(
              strippedCredentials[companyName]
            )) {
              if (strippedCredentials[companyName][areaName] === false) {
                strippedCredentials[companyName][areaName] = deleteValue
              }
            }
          }
          return strippedCredentials
        }

        const { credentials: _, ...userStrings } = userData
        const {
          credentials: __,
          passwordConfirm: ___,
          ...originalUserStrings
        } = initialFormValues
        await Api.User.update({
          ...{
            ...diff(originalUserStrings, userStrings),
            credentials: credentialsStrip(userData.credentials),
          },
          uid: uidOrNew!,
        })
      }
      navigate!('/users')
    } catch (e) {
      return { [FORM_ERROR]: e.message || JSON.stringify(e) }
    }
  }

  if (loading) return <LoadingScreen />
  return (
    <>
      <NavBar />
      <Container fluid>
        <Row className='collection-controls'>
          <Col md={12}>
            <h1>{translate(`${CREATING ? 'New' : 'Edit'} user`)}</h1>
          </Col>
        </Row>

        <FinalFrom<UserFormValues>
          onSubmit={onSubmit}
          validate={({ email, password, passwordConfirm }) => {
            const errors = {} as { [key: string]: string }

            const emailError = Validation.all(
              Validation.required(),
              Validation.isEmail
            )(email)
            if (emailError) errors.email = emailError

            if (passwordSectionShown) {
              const passwordError = Validation.all(
                Validation.required(),
                Validation.isOfLength(6)
              )(password)

              const passwordConfirmError =
                Validation.required()(passwordConfirm) ||
                (password !== passwordConfirm
                  ? 'Passwords do not match'
                  : undefined)

              if (passwordError) errors.password = passwordError
              if (passwordConfirmError)
                errors.passwordConfirm = passwordConfirmError
            }

            return errors
          }}
          initialValues={initialFormValues}
          mutators={{ ...arrayMutators }}
        >
          {({ handleSubmit, error, dirty, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={7}>
                  <h6 className='heading-small text-muted mb-4'>
                    {translate('User information')}
                  </h6>

                  <Row>
                    <Col sm={12} md={6}>
                      <FinalField
                        name='email'
                        validate={Validation.all(
                          Validation.required(),
                          Validation.isEmail
                        )}
                      >
                        {({ input, meta: { error: emailError, touched } }) => (
                          <TextField
                            name='Username'
                            type='email'
                            placeholder='sample_email@mail.com'
                            input={input}
                            error={emailError}
                            touched={touched}
                          />
                        )}
                      </FinalField>
                    </Col>
                    <Col>
                      <FinalField name='name'>
                        {({ input }) => <TextField name='Name' input={input} />}
                      </FinalField>
                    </Col>
                  </Row>
                  <Row>
                    {passwordSectionShown && (
                      <>
                        <Col sm={12} md={6}>
                          <FinalField name='password'>
                            {({
                              input,
                              meta: {
                                error: passwordError,
                                touched: passwordTouched,
                              },
                            }) => (
                              <TextField
                                name='Password'
                                input={input}
                                error={passwordError}
                                touched={passwordTouched}
                                type='password'
                              />
                            )}
                          </FinalField>
                        </Col>
                        <Col>
                          <FinalField name='passwordConfirm'>
                            {({
                              input,
                              meta: {
                                error: passwordConfirmError,
                                touched: passwordConfirmTouched,
                              },
                            }) => (
                              <TextField
                                name='Password'
                                input={input}
                                error={passwordConfirmError}
                                touched={passwordConfirmTouched}
                                type='password'
                              />
                            )}
                          </FinalField>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    {!CREATING && (
                      <>
                        <Col>
                          <Button
                            onClick={() =>
                              setPasswordSectionShown(!passwordSectionShown)
                            }
                          >
                            {translate(
                              passwordSectionShown
                                ? 'leave password as is'
                                : 'reset password'
                            )}
                          </Button>
                        </Col>
                        <Col />
                      </>
                    )}
                  </Row>
                </Col>
                <Col className='d-block d-sm-none'>
                  <hr className='my-4 ' />
                </Col>

                <Col md={{ size: 4, offset: 1 }}>
                  <h6 className='heading-small text-muted mb-4'>
                    {translate('Permissions')}
                  </h6>
                  <Row>
                    <Col>
                      <FinalField name='admin' type='checkbox'>
                        {({ input, form }) => (
                          <FormGroup>
                            <FormGroup>
                              <InputGroup>
                                <Row>
                                  <Col md={8}>
                                    <label className='form-control-label'>
                                      {translate('Is Administrator?')}
                                    </label>
                                  </Col>
                                  <Col>
                                    <Toggle {...input} />
                                  </Col>
                                </Row>
                              </InputGroup>
                            </FormGroup>
                          </FormGroup>
                        )}
                      </FinalField>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr className='my-4' />

              <h6 className='heading-small text-muted mb-4'>
                {translate('Credentials')}
              </h6>
              <Container fluid>
                <FieldArray<UserFormCompany>
                  name='credentials'
                  isEqual={isEqual}
                >
                  {({ fields: companies }) => (
                    <>
                      {companies.map((company, companyIndex) => (
                        <CompanyRow
                          key={company}
                          company={company}
                          companyIndex={companyIndex}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </Container>
              {error && <Alert color='danger'>{error}</Alert>}
              <Footer
                saveEnabled={
                  dirty && Validation.isObjectEmpty(errors) && !error
                }
                onDelete={
                  !CREATING && user?.uid !== uidOrNew ? onDelete : undefined
                }
              />
            </Form>
          )}
        </FinalFrom>
      </Container>
    </>
  )
}
