import React, { FunctionComponent } from 'react'
import { FormFeedback, FormGroup, Input, InputGroup } from 'reactstrap'
import { translate } from '../language_provider';

export const TextField: FunctionComponent<{
  name: string
  input: any
  error?: string
  touched?: boolean
  placeholder?: string
  type?: string
}> = ({ input, error, touched, name, placeholder, type }) => (
  <FormGroup>
    <label className='form-control-label'>{translate(name)}</label>
    <InputGroup>
      <Input
        {...input}
        type={type}
        placeholder={placeholder}
        autoComplete='new-email'
        invalid={error && touched}
      />
      {error && touched && <FormFeedback>{translate(error)}</FormFeedback>}
    </InputGroup>
  </FormGroup>
)
