import React, { Component } from 'react'

import firebase from 'firebase'
import { Timestamp } from '../../fb_app'
import { JMM } from '../../jmm_schema'

import ReactDatePicker from 'react-datepicker'
import { Button, Col, FormGroup, Input, Row } from 'reactstrap'

import update from 'immutability-helper'

import { translate } from '../language_provider';

type RecordCreationData = Omit<JMM.RecordEntry, 'attachments'> & {
  attachments?: File[]
}

interface RFProps {
  onSave: (record: RecordCreationData) => any
}

interface RFState extends Omit<JMM.RecordEntry, 'attachments'> { }

const freshState = () => ({
  name: '',
  timeSpan: {
    from: Timestamp.fromDate(new Date()),
    to: (() => {
      const t = new Date()
      return Timestamp.fromDate(
        new Date(t.getFullYear() + 1, t.getMonth(), t.getDate())
      )
    })(),
  } as JMM.TimeSpan,
})

export class RecordForm extends Component<RFProps, RFState> {
  state = freshState()

  fileInput: HTMLInputElement | null = null

  render() {
    const {
      name,
      timeSpan: { from, to },
    } = this.state
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <label className='form-control-label'>{translate('Name')}</label>
            <Input
              name='name'
              type='text'
              value={name}
              autoComplete=''
              onChange={({ currentTarget: { value: newName } }) =>
                this.setState({ name: newName })
              }
              className='form-control-alternative'
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <label className='form-control-label'>{translate('From')}</label>
            <Row>
              <Col>
                <ReactDatePicker
                  className='form-control-alternative form-control'
                  selected={from.toDate()}
                  onChange={newFrom => {
                    if (newFrom)
                      this.setState(prevState =>
                        update(prevState, {
                          timeSpan: {
                            from: {
                              $set: Timestamp.fromDate(
                                new Date(newFrom)
                              ) as firebase.firestore.Timestamp,
                            },
                          },
                        })
                      )
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <label className='form-control-label'>{translate('To')}</label>
            <Row>
              <Col>
                <ReactDatePicker
                  className='form-control-alternative form-control'
                  selected={to.toDate()}
                  onChange={newTo => {
                    if (newTo)
                      this.setState(prevState =>
                        update(prevState, {
                          timeSpan: {
                            to: {
                              $set: Timestamp.fromDate(
                                new Date(newTo)
                              ) as firebase.firestore.Timestamp,
                            },
                          },
                        })
                      )
                  }}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col md={8}>
          <FormGroup>
            <label className='form-control-label'>{translate('File')}</label>
            <Input
              type='file'
              multiple
              onChange={event => (this.fileInput = event.target)}
              className='form-control'
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup className='collection-form'>
            <Button
              onClick={() => {
                this.handleSubmit()
              }}
              className='my-4'
              color='default'
              outline
            >
              {translate('Add')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    )
  }

  handleSubmit() {
    if (!this.state.name) {
      // TODO toast?
      return
    }
    const { onSave } = this.props
    if (this.fileInput && this.fileInput.files && this.fileInput.files.length) {
      onSave({ ...this.state, attachments: Array.from(this.fileInput.files) })
      this.fileInput.value = ''
    } else {
      onSave({ ...this.state })
    }
    this.setState(freshState)
  }
}
