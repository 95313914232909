import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { LangToggle } from '../components/lang_toggle'

import { translate } from '../components/language_provider'

export const TermsConditions: FunctionComponent<RouteComponentProps<{
  lang: string
}>> = ({ lang }) => (
  <>
    <div style={{ margin: '7vw' }}>
      <LangToggle lang={lang} />
    </div>
    <div style={{ margin: '7vw' }}>
      <strong>{translate('Terms & Conditions')}</strong>
      <p>{translate('termsIntro')}</p>
      <p>{translate('termsCommitment')}</p>
      <p>{translate('termsDataUse')}</p>
      <div>
        <p>
          {translate(
            'The app does use third party services that declare their own Terms and Conditions.'
          )}
        </p>
        <p>
          {translate(
            'Link to Terms and Conditions of third party service providers used by the app'
          )}
        </p>
        <ul>
          <li>
            <a
              href='https://policies.google.com/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href='https://firebase.google.com/terms/analytics'
              target='_blank'
              rel='noopener noreferrer'
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href='https://expo.io/terms'
              target='_blank'
              rel='noopener noreferrer'
            >
              Expo
            </a>
          </li>
        </ul>
      </div>
      <p>{translate('termsResponsibilities')}</p>
      <p>{translate('termsWifi')}</p>
      <p>{translate('termsDevice')}</p>
      <p>{translate('termsAvailability')}</p>
      <p>{translate('termsAppUpdate')}</p>
      <p>
        <strong>{translate('Changes to This Terms and Conditions')}</strong>
      </p>
      <p>{translate('termsChanges')}</p>
      <p>
        {translate('These terms and conditions are effective as of')} 2020-05-11
      </p>
      <p>
        <strong>{translate('Contact Us')}</strong>
      </p>
      <p>{translate('termsContactInfo')} <a href={'mailto:dev@ggo.blue'}>dev@ggo.blue</a></p>
    </div>
  </>
)
