import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { Jumbotron } from 'reactstrap'

const flexCenter =
  'd-flex flex-fill align-items-center justify-content-center flex-column'

export const NotFound: FunctionComponent<RouteComponentProps> = props => (
  <Jumbotron fluid className={flexCenter} style={{ height: '100vh' }}>
    <p style={{ fontSize: '10em' }}>404</p>
    <p>{`the path ${props.location?.pathname} is not in this app`}</p>
  </Jumbotron>
)
