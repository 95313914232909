import { RouteComponentProps } from '@reach/router'
import React, { FunctionComponent } from 'react'
import { LangToggle } from '../components/lang_toggle'

import { translate } from '../components/language_provider'

export const Support: FunctionComponent<RouteComponentProps<{
  lang: string
}>> = ({ lang }) => (
  <>
    <div style={{ margin: '7vw' }}>
      <LangToggle lang={lang} />
    </div>
    <div
      style={{
        margin: '7vw'
      }}
    >
      <strong>Support</strong> <br></br>
      <p>{translate('supportContactInfo')} <a href={'mailto:dev@ggo.blue'}>dev@ggo.blue</a></p>
    </div>
  </>
)
