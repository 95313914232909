import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

firebase.initializeApp({
  apiKey: 'AIzaSyBUDHLVUBQ00Hea5FZc23kyqPkvNyQw5c8',
  authDomain: 'jmmap-cool.firebaseapp.com',
  databaseURL: 'https://jmmap-cool.firebaseio.com',
  projectId: 'jmmap-cool',
  storageBucket: 'jmmap-cool.appspot.com',
  messagingSenderId: '407327540400',
  appId: '1:407327540400:web:5ac33bb5d14d0dec01a775',
})

// firebase.functions().useFunctionsEmulator('http://localhost:5001');

export type DocumentReference<T> = firebase.firestore.DocumentReference<T>
export const deleteValue: any = firebase.firestore.FieldValue.delete()
export const Timestamp = firebase.firestore.Timestamp
export const TaskState = firebase.storage.TaskState
export const TaskEvent = firebase.storage.TaskEvent

export const Storage = firebase.storage()
export const Firestore = firebase.firestore()
export const Functions = firebase.functions()
export const Auth = firebase.auth()
